<template>
  <div>
    <ServerError v-if="ServerError" />
     <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-center>
        <span class="itemHeading"> Add New {{ dataType }} </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">{{ dataType }} </span>
            <v-text-field
              ref="name"
              color="#FDCE48"
              class="formClass"
              placeholder="Name"
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              color="#000"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Add {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  props: ["dataType", "addUrl", "subItemId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      name: null,
    };
  },

  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addUser: false,
      });
    },
    validateInput() {
      if (!this.name) {
        this.msg = "Please Provide " + this.dataType + " name";
        this.showSnackBar = true;
        return;
      } else {
        this.addDynamicData();
      }
    },
    addDynamicData() {
      // officerData["role"] = this.officerType;
      var data = {};
      data["name"] = this.name;
      axios({
        method: "post",
        url: this.addUrl,
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "New " + this.dataType + " Created";
            this.showSnackBar = true;
            this.name = null;

            this.$emit("stepper", {
              type: "Add",
              getData: true,
              addUser: false,
            });
          } else {
            this.msg = response.data.msg;
            this.ServerError = true;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>