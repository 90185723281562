<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="addDataDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <AddData :dataType="'Blog Category'" :addUrl="'/home/newblog/category/add'" @stepper="winStepper"/>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <EditData
              v-bind:storage="editCategoryData"
              @stepper="winStepper"
              :key="editCategoryData._id"
              :dataType="'Blog Category'"
              :editUrl="'/home/newblog/category/edit'"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start pt-3 pl-2 pr-5>
      <v-flex align-self-center xs6 sm9 md10 text-left>
        <span class="itemHeading1"> Blog Categories </span>
      </v-flex>
      <v-flex align-self-center xs6 sm3 md2 lg2 xl1 px-3>
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          @click="addDataDialog = true"
          class="itemValue"
        >
          Add New Data
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4>
      <template v-for="(item, i) in Categories">
        <v-flex xs12 lg6 xl6 pa-2 :key="i">
          <CategoryItem
            :deleteUrl="'/home/newblog/category/remove'"
            v-bind:storage="item"
            :dataType="'Blog Category'"
            @stepper="winStepper"
          />
        </v-flex>
      </template>
      <v-flex xs12 v-if="Categories.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import CategoryItem from "./blogCategoryItem";
import AddData from "./addBlogCategory";
import EditData from "./editBlogCategory";
export default {
  components: {
    CategoryItem,
    AddData,
    EditData,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      //   users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      addDataDialog: false,
     
      Categories: [],
      editDialog: false,
       editCategoryData: {},
    };
  },

  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/newblog/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.Categories = response.data.data;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "Add") {
        if (windowData.getData) this.getData();
        if (!windowData.addUser) this.addDataDialog = false;
      }
       else if (windowData.type == "Edit") {
        if (windowData.categoryData) this.editCategoryData = windowData.categoryData;
        if (windowData.getData) this.getData();
        this.editDialog = windowData.editData;
      } 
      else if (windowData.type == "Delete") {
        if (windowData.pageResponse.status) {
          this.msg = "Blog Category Deleted Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "Blog Category cannot be Deleted";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>